import React from 'react';
import PropTypes from 'prop-types';

export const OurStory = props => {
  const { story } = props;

  return (
    <div className="container kos__our-story">
      <h3 className="has-text-centered story-subtitle">{story.subtitle}</h3>
      <h2 className="has-text-centered story-title">{story.title}</h2>
      <p className="has-text-centered story-body">{story.body}</p>
    </div>
  );
};

OurStory.propTypes = {
  story: PropTypes.shape({
    subtitle: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string
  })
};
